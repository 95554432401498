export const servicesPageItem = [
    {
        name: "Permanent Recruitment",
        routePath: "/facilities-manager",
      },
      {
        name: "Contract Recruitment",
        routePath: "/contract-recruitment",
      },
      {
        name: "Executive Placements",
        routePath: "/executive-placements",
      },
      {
        name: "Africa Recruitment",
        routePath: "/africa-recruitment",
      },
      {
        name: "Coaching",
        routePath: "/coaching",
      },
    {
      name: "Ad Response Handling",
      routePath: "/ad-response",
    },   
    
    {
        name: "Job Advertisement Board",
        routePath: "/job-advertisement",
      },
      {
        name: "Talent Networking",
        routePath: "/talent-networking",
      },
   
    
    {
      name: "Employment Engagement Surveys",
      routePath: "/employment-engagement",
    },
   
    
    {
      name: "Learnerships",
      routePath: "/learnerships",
    },
    
    // Add more services as needed
  ];


export default [
    {
        id: 1,
        label: "Home",
        items: [
           
            {
                name: "Home Page 09",
                routePath: "/home-9",
            },
            {
                name: "Home Page 10",
                routePath: "/home-10",
            },
            {
                name: "Home Page 11",
                routePath: "/home-11",
            },
            {
                name: "Home Page 12",
                routePath: "/home-12",
            },
            {
                name: "Home Page 13",
                routePath: "/home-13",
            },
            {
                name: "Home Page 14",
                routePath: "/home-14",
            },
            {
                name: "Home Page 15",
                routePath: "/home-15",
            },
            {
                name: "Home Page 16",
                routePath: "/home-16",
            },
            {
                name: "Home Page 17",
                routePath: "/home-17",
            },
        ],
    }
   
    ,
    {
        id: 8,
        label: "Blog",
        items: [
            {
                name: "Blog List V1",
                routePath: "/blog-list-v1",
            },
            {
                name: "Blog List V2",
                routePath: "/blog-list-v2",
            },
            {
                name: "Blog List V3",
                routePath: "/blog-list-v3",
            },
            {
                name: "Blog Details",
                routePath: "/blog-details/1",
            },
        ],
    },
    {
        id: 9,
        label: "Pages",
        items: [
            {
                name: "About",
                routePath: "/about",
            },
            {
                name: "Pricing",
                routePath: "/pricing",
            },
            {
                name: "FAQ's",
                routePath: "/faq",
            },
            {
                name: "Terms",
                routePath: "/terms",
            },
            {
                name: "Invoice",
                routePath: "/invoice",
            },
            {
                name: "Contact",
                routePath: "/contact",
            },
            {
                name: "404",
                routePath: "/404",
            },
        ],
    },
];
