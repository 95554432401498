import Link from 'next/link';

const SidebarHeader = () => {
  return (
    <div className='pro-header' style={{backgroundColor:'#500f0b'}}>
      <Link href='/'>
        <img src='images/origins-logo.png' alt='brand' width={100} height={50} />
      </Link>
      {/* End logo */}

      <div className='fix-icon' data-bs-dismiss='offcanvas' aria-label='Close'>
        <span className='flaticon-close'></span>
      </div>
      {/* icon close */}
    </div>
  );
};

export default SidebarHeader;
