"use client";
import Link from "next/link";
import { useEffect, useState } from "react";
import {
  blogItems,
  candidateItems,
  employerItems,
  findJobItems,
  homeItems,
  pageItems,
  shopItems,
  servicesPageItem,
} from "../../../data/mainMenuData";
import {
  ProSidebarProvider,
  Sidebar,
  Menu,
  MenuItem,
  SubMenu,
} from "react-pro-sidebar";

import mobileMenuData from "../../../data/mobileMenuData";
import SidebarFooter from "./SidebarFooter";
import SidebarHeader from "./SidebarHeader";
import {
  isActiveParent,
  isActiveLink,
  isActiveParentChaild,
} from "../../../utils/linkActiveChecker";
import { useRouter } from "next/router";

const Index = () => {
  const router = useRouter();
  const [isDropdownOpen, setDropdownOpen] = useState(false);

  useEffect(() => {
    const handleTouchStart = () => {
      setDropdownOpen(false);
    };

    window.addEventListener("touchstart", handleTouchStart);

    return () => {
      window.removeEventListener("touchstart", handleTouchStart);
    };
  }, []);

  return (
    <div
      className="offcanvas offcanvas-start mobile_menu-contnet"
      tabIndex="-1"
      id="offcanvasMenu"
      data-bs-scroll="true"
    >
      <SidebarHeader />
      {/* End pro-header */}

      <ProSidebarProvider>
        <Sidebar>
          <Menu>
            {/* Home */}
            <li
              className={
                isActiveParent(homeItems, router.asPath) ? "current" : ""
              }
            >
              <MenuItem
                className={
                  isActiveParent(homeItems, router.asPath) ? "current" : ""
                }
              >
                <Link href="/">Home</Link>
              </MenuItem>
            </li>

            {/* About */}
            <li
              className={
                isActiveParent(pageItems, router.asPath) ? "current" : ""
              }
            >
              <MenuItem
                className={
                  isActiveParent(pageItems, router.asPath) ? "current" : ""
                }
              >
                <Link href="/about">About</Link>
              </MenuItem>
            </li>
            <li className="dropdown">              
            <SubMenu label="Services" style={{color:"#80241d"}}>
              {servicesPageItem.map((item, index) => (
                <MenuItem key={index} className={isActiveLink(item.routePath, router.asPath) ? "current" : ""}>
                  <Link href={item.routePath}>{item.name}</Link>
                </MenuItem>
              ))}
            </SubMenu>
            </li>
            <li className={isActiveParent(pageItems, router.asPath) ? "current" : ""}>
          <MenuItem href="/jobseekers">For Jobseekers</MenuItem>
        </li>
        <li className={isActiveParent(pageItems, router.asPath) ? "current" : ""}>
          <MenuItem href="/employer">For Employers</MenuItem>
        </li>

            {/* Find Jobs */}
            {/* <li
              className={
                isActiveParent(findJobItems, router.asPath) ? "current" : ""
              }
            >
              <MenuItem
                className={
                  isActiveParent(findJobItems, router.asPath) ? "current" : ""
                }
              >
                <Link href="/job-list">Find Jobs</Link>
              </MenuItem>
            </li> */}

            {/* Services Dropdown */}
        

            {/* Blog */}
            <li
              className={
                isActiveParent(blogItems, router.asPath) ? "current" : ""
              }
            >
              <MenuItem
                className={
                  isActiveParent(blogItems, router.asPath) ? "current" : ""
                }
              >
                <Link href="/blog-list">Blog</Link>
              </MenuItem>
            </li>

            {/* Contact */}
            <li
              className={
                isActiveParent(pageItems, router.asPath) ? "current" : ""
              }
            >
              <MenuItem
                className={
                  isActiveParent(pageItems, router.asPath) ? "current" : ""
                }
              >
                <Link href="/contact">Contact</Link>
              </MenuItem>
            </li>
          </Menu>
        </Sidebar>
      </ProSidebarProvider>

      <SidebarFooter />
    </div>
  );
};

export default Index;
