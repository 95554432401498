const graphQLAPI = process.env.NEXT_PUBLIC_GRAPHQL


const setData = async (mutation, data = {}, additionalPath = '') => {
  const query = JSON.stringify({
    query: mutation,
    variables: data
  });
  console.log(graphQLAPI+additionalPath)
  const response = await fetch(`${graphQLAPI}${additionalPath}`, {
    headers: {
      'content-type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE, OPTIONS',
      'Access-Control-Allow-Headers': 'Content-Type, Authorization'
    },
    method: 'POST',
    body: query,
  });

  const responseJson = await response.json();
  return responseJson.data;
};

export default setData;