import Link from 'next/link';
import MobileSidebar from './mobile-sidebar';
import Image from 'next/image';

const MobileMenu = () => {
  return (
    // <!-- Main Header-->
    <header className='main-header main-header-mobile' style={{backgroundColor:'#500f0b'}}>
      <div className='auto-container'>
        {/* <!-- Main box --> */}
        <div className='inner-box'>
          <div className='nav-outer'>
            <div className='logo-box'>
              <div className='logo'>
                <Link href='/'>
                <Image src="/origins-logo.png" alt='brand' width={100} height={50} />
                </Link>
              </div>
            </div>
            {/* End .logo-box */}

            <MobileSidebar />
            {/* <!-- Main Menu End--> */}
          </div>
          {/* End .nav-outer */}

          <div className='outer-box'>
            <div className='login-box'>
              <a
                href='#'
                className='call-modal'
                data-bs-toggle='modal'
                data-bs-target='#loginPopupModal'
              >
                {/* <span   className='icon icon-user'></span> */}
              </a>
            </div>
            {/* login popup end */}

            <a
              href='#'
              className='mobile-nav-toggler'
              data-bs-toggle='offcanvas'
              data-bs-target='#offcanvasMenu'
            >
              <span className='flaticon-menu-1'></span>
            </a>
            {/* right humberger menu */}
          </div>
        </div>
      </div>
    </header>
  );
};

export default MobileMenu;
