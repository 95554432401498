import { useMutation } from "react-query";
import setData from '../../../../helpers/setData';
import {createNewUser} from '../../../../queries/users.';
import { useState } from 'react';




const FormContent = () => {
  
  const [formData, setFormData] = useState({
    email: '',
    password: ''
  });

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await fetch('http://localhost:8055/graphql/system', {
        method: 'POST',
        headers: {
                    
          'Content-Type':'application/json; charset=utf-8',
          //'Access-Control-Allow-Origin':'*'
       },
        body: JSON.stringify({
          query: `            
          #graphql
          mutation createNewUser($data: [create_directus_users_input!]) {
              create_users_items(data: $data)
          }
          `,
          variables: {
            data: [{
              email: event.target.username.value,
              password: event.target.password.value,
              role: 'f2e309a4-c4f2-418c-91f2-5b4d4b2f2b08', // Manually enter the role here
              status: 'active', // Manually enter the status here
              provider: ''
            }]
          }
        })
      });

      const { data, errors } = await response.json();

      if (errors) {
        throw new Error(errors[0].message);
      }

      console.log('User created:', data.create_users_items);
       // Redirect to home page
       
      } catch (error) {
        console.error('Error creating user:', error);
      }

     
  };

  


  return (
    <form method="post"  onSubmit={(event)=>handleSubmit(event)}>
      <div className="form-group">
        <label>Email Address</label>
        <input type="email" name="username" placeholder="Username" required />
      </div>
      {/* name */}

      <div className="form-group">
        <label>Password</label>
        <input
          id="password-field"
          type="password"
          name="password"
          placeholder="Password"
        />
      </div>
      {/* password */}

      <div className="form-group">
        <button className="theme-btn btn-style-one" type="submit">
          Register
        </button>
      </div>
      {/* login */}
    </form>
  );
};

export default FormContent;
